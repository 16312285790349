import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    User,
    SearchUsersQuery,
    SearchUsersGQL,
    SearchUserInput,
    CreateUserGQL,
    UpdateUserGQL,
    UserPage,
    Unity,
    GetUnitiesByCountryQuery,
    GetUnitiesByCountryGQL,
    Country,
    GetCountriesQuery,
    GetCountriesGQL,
    UpdateUserInput, CreateUserInput, RemoveUserGQL,
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {ExcelService} from '../../service/excel.service';
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {
    user = new User();
    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    users: Array<User> = [];
    usersQuery: QueryRef<SearchUsersQuery>;
    modelSearchResult: UserPage = new UserPage();

    filter: SearchUserInput = new SearchUserInput();
    currentPage = 1;

    model: User = new User();
    modelUser: User = new User();

    unityId: string;
    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    filterUnities: Array<Unity>;
    filterUnityId: string;
    filterCountries: Array<Country>;
    filterCountryId: string;

    userId: string;
    selectedCountryId: string;
    selectedUnityId: string;
    selectPassword: string;
    userMicrosof: boolean = false;
    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    labelCountry: boolean;
    labelUnity: boolean;
    exportExcel: boolean;
    newUser: boolean;
    actionUpdate: boolean;

    constructor(public router: Router,
                public translate: TranslateService,
                public createUser: CreateUserGQL,
                public updateUser: UpdateUserGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private getCountriesGQL: GetCountriesGQL,
                private removeUser: RemoveUserGQL,
                public excelService: ExcelService,
                public userService: UserService,
                private searchUsersGQL: SearchUsersGQL) {
        super(router, translate);
    }

    ngOnInit() {
        if (this.actionUserProfile() === false) {
            this.filterUnityId = this.user.unity.id;
        }
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        this.filterGetCountries();
        this.getCountries();
        this.getUnities();
        this.getUsers();
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }


    onFilter() {
        this.currentPage = 1;
        if (this.actionUserProfile() === false) {
            this.filterUnityId = this.user.unity.id;
        }
        this.filter.role = this.filter.role;
        this.filter.unityId = this.filterUnityId;
        this.filter.countryId = this.filterCountryId;
        this.usersQuery.refetch({page: this.currentPage, input: this.filter});
    }

    pageChanged(event) {
        this.currentPage = event;
        if (this.actionUserProfile() === false) {
            this.filterUnityId = this.user.unity.id;
        }
        this.filter.role = this.filter.role;
        this.filter.unityId = this.filterUnityId;
        this.filter.countryId = this.filterCountryId;
        this.usersQuery.refetch({page: this.currentPage, input: this.filter});
        // this.onFilter();
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
        $('#svg_login_profile').addClass('d-none');
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('profile.edit.warningUploadExtensions'), this.translate.instant('profile.edit.warningAttention'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `Nome<br>`},
            {value: this.selectedCountryId, text: `País<br>`},
            {value: this.selectedUnityId, text: `Unidade<br>`},
            {value: this.model.profile, text: `Perfil<br>`},
            {value: this.model.office, text: `Cargo<br>`},
            {value: this.model.phone, text: `Telefone<br>`},
            {value: this.model.email, text: `Email<br>`},
            // {value: this.selectPassword, text: `Senha<br>`},
            {value: this.model.identifier, text: `ID<br>`},
        ];

        if(this.userMicrosof === false) {
            arrValidateFields.push(
                {value: this.selectPassword, text: `Senha<br>`}
            )
        }

        const stringError = this.validateField(arrValidateFields);

        if (!this.isEMailValid(this.model.email)) {
            return;
        }

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.model.status)){
            this.model.status = false;
        }
        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreateUser();
        } else {
            this.actionUpdateUser();
        }
    }

    filterGetCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.filterCountries = data.countries as Array<Country>;
        });
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.unityQuery = this.getUnitiesByCountryGQL.watch(
            {countryId: this.isNullOrUndefined(this.filterCountryId) ? '' : this.filterCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    actionCreateUser() {
        if (this.actionUserProfile() === false) {
            this.selectedUnityId = this.user.unity.id;
        }
        const data: CreateUserInput = {
            name: this.model.name,
            unity: {
                id: this.selectedUnityId,
            },
            phone: this.model.phone,
            email: this.model.email,
            profile: this.model.profile,
            identifier: this.model.identifier,
            status: this.model.status,
            office: this.model.office,
            password: this.selectPassword,
        };
        this.createUser.mutate({data: data}).subscribe((result) => {
            this.model = new User();
            this.usersQuery.refetch({page: 1, input: this.filter});
            this.showMessage(this.translate.instant('success'), this.translate.instant('usuariocriado'), 'success');
            this.closeModal('userModal');
        });
    }

    actionUpdateUser() {
        const data: UpdateUserInput = {
            id: this.model.id,
            name: this.model.name,
            country: {
                id: this.selectedCountryId,
            },
            unity: {
                id: this.selectedUnityId,
            },
            profile: this.model.profile,
            phone: this.model.phone,
            email: this.model.email,
            status: this.model.status,
            office: this.model.office,
            identifier: this.model.identifier,
        };
        this.updateUser.mutate({data: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch({page: 1, input: this.filter});
            this.showMessage(this.translate.instant('success'), this.translate.instant('usuarioeditado'), 'success');
            this.closeModal('userModal');
        });
    }

    getUsers(): void {
        if (this.actionUserProfile() === false) {
            this.filter.unityId = this.user.unity.id;
        }
        this.usersQuery = this.searchUsersGQL.watch({page: this.currentPage, input: this.filter});
        this.usersQuery.valueChanges.subscribe(({data}) => {
            this.modelSearchResult = data.searchUsers as UserPage;
            this.users = data.searchUsers.users as Array<User>;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getUnities() {
        this.unityQuery = this.getUnitiesByCountryGQL.watch(
            {countryId: this.isNullOrUndefined(this.selectedCountryId) ? '' : this.selectedCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    create() {
        this.selectPassword = null;
        this.model = new User();
        this.selectedCountryId = null;
        this.selectedUnityId = null;
        this.showModal('userModal');
    }

    actionEdit(user: User) {
        Object.assign(this.model, user);
        this.userId = this.model.id;
        this.selectedUnityId = this.model.unity.id;
        this.selectedCountryId = this.model.unity.country.id;
        this.getUnities();
        this.showModal('userModal');
    }

    actionDelete(userid) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('excluirusuario'), () => {
            this.removeUser.mutate({id: userid}).subscribe((result) => {
                this.usersQuery.refetch({page: 1, input: this.filter});
                super.showMessage(this.translate.instant('success'), this.translate.instant('usuariodeletado'), 'success');
            });
        });
    }

    actionExcel() {
        this.excelService.getExcelUsers(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    onClearAuthor(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelUser[str] = '';
            }
        });
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelCountry = true;
                this.labelUnity = true;
                this.exportExcel = true;
                this.newUser = true;
                this.actionUpdate = true;
                break;
            case 'local-manager':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.newUser = true;
                this.actionUpdate = true;
                break;
            case 'user':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-leader':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-conductor':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
            case 'vigilant':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
            case 'lecturer':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
            case 'corporate-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
            case 'local-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.newUser = false;
                this.actionUpdate = false;
                break;
        }
    }
}
