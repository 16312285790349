<div class="login">
    <div class="header">
        <div class="desc">
            <p>{{"login.security" | translate}}</p>
        </div>

    </div>
    <div class="content">
        <div class="form">
            <b>{{"login.login" | translate}}</b>
            <div class="input-material w-80">
                <input id="fieldRE" class="form-control" type="text" required [(ngModel)]="modelIdentifier"
                       (blur)="onClearModelUser($event, 'cpf')"/>
                <label for="fieldRE">
                    <span>{{"login.id" | translate}}</span>
                </label>
            </div>
            <div class="input-material w-80">
                <input id="senha" class="form-control" type="password" required [(ngModel)]="modelPassword"/>
                <label for="senha">
                    <span>{{"login.pass" | translate}}</span>
                </label>
            </div>
            <div class="row justify-content-end align-items-end w-80 mt-3 mb-3">
                <a class="is-cursor" (click)="showModal('modalForgot')">{{"login.forgot-pass" | translate}}</a>
            </div>
            <button class="btn btn-tertiary  w-80 mt-3" tabindex="0" (click)="onLogin()">
                <span class="pl-2">{{"login.enter" | translate}}</span>
            </button>
            <div class="d-flex align-items-baseline justify-content-center w-80">
                <div class="divisor"></div>
                <p class="mt-4 mx-3">
                    ou
                </p>
                <div class="divisor"></div>
            </div>
            <a (click)="redirectAuth()" class="btn btn-primary btn-lg text-white w-80">
                <svg-icon src="assets/svg/office.svg"></svg-icon>
            </a>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalForgot" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>Esqueci minha senha</b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalForgot')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mt-5 mb-2">
                        <div class="col-12 row d-flex justify-content-center mb-3">
                            <div class="d-flex justify-content-center col-md-6 col-12">
                                <img src="assets/images/photo.png" class="mb-3">
                            </div>
                            <div class="col-md-6 col-12">
                                <h3 class="text-primary">
                                    <b>Recupere sua senha informando o ID cadastrado.</b>
                                </h3>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material input_small">
                                <input id="forgotPass" class="form-control" type="text" [(ngModel)]="modelForgotCPF"
                                       required maxlength="250"/>
                                <label for="forgotPass">ID</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3"
                                    (click)="actionForgotPassword()"
                            ><p class="text-white m-0">Enviar</p></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


