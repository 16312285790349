<div class="page">
    <div class="page-padding">
        <div class="page-header-custom-secondary">
            <svg-icon class="svg-service is-cursor" [routerLink]="'/services'" src="assets/svg/arrow.svg"></svg-icon>
            <h2 class="page-title"><span>{{'agendamento'|translate}}<strong> {{'servico'|translate}}</strong></span>
            </h2>
        </div>
        <!--Data e Local-->
        <div class="card_ret row">
            <div class="col-12 col-md-3">
                <span><strong>{{'dataelocal'|translate}}</strong></span>
            </div>
            <div class="row col-12 col-md justify-content-end">
                <div class="col-12 col-md-4 mb-2">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text" require
                               [(ngModel)]="model.requestNumber"/>
                        <!--<select name="country" id="country"
                                class="form-control" required>
                            <option>Refeitório</option>
                            <option></option>
                        </select>-->
                        <label>*{{'requestnumber'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-2">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text" require
                               [(ngModel)]="model.area"/>
                        <!--<select name="country" id="country"
                                class="form-control" required>
                            <option>Refeitório</option>
                            <option></option>
                        </select>-->
                        <label>*{{'areaservico'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-2">
                    <div class="input-material select" *ngIf="labelUnity">
                        <select name="unity" id="unity" [(ngModel)]="unityID"
                                class="form-control" required>
                            <option [ngValue]="undefined">{{'occurrence.selectunit'|translate}}</option>
                            <option *ngFor="let c of unities" value="{{c.id}}">{{c.name}}</option>
                        </select>
                        <label class="disabled" for="unity">{{'unidade'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-2">
                    <div class="input-material">
                        <input name="name" class="form-control date-picker" type="text" required/>
                        <label>{{'dataentrada'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-2">
                    <div class="input-material">
                        <input name="name" class="form-control date-picker-exit" type="text" required/>
                        <label>{{'datasaida'|translate}}</label>
                    </div>
                </div>
                <!--<div class="col-12 col-md-4 mb-2">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text" required/>
                        <label>Carregamento</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-2">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text"
                               required/>
                        <label>Entrada</label>
                    </div>
                </div>-->
            </div>

        </div>

        <!--Informações do prestador-->
        <div class="card_ret row">
            <div class="col-12 col-md-3">
                    <span>
                        <strong>
                        {{'informacoes'|translate}}
                            <br></strong>
                        {{'doprestador'|translate}}
                     </span>
            </div>

            <div class="row col-12 col-md justify-content-between">
                <div class="col-12 col-md-4 mt-2">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text"
                               [(ngModel)]="model.name"
                               required/>
                        <label>*{{'nomeprestador'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-2 ">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text"
                               [(ngModel)]="model.cpf"

                               required/>
                        <label>*ID</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-2 ">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text"
                               [(ngModel)]="model.cnpj"
                               [mask]="'00.000.000-0000/00'"
                               [dropSpecialCharacters]="false"
                               [clearIfNotMatch]="true"
                               (blur)="onClearVictim($event, 'document')"
                               required/>
                        <label>*CNPJ</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-2 ">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text"
                               [(ngModel)]="model.company"
                               [maxLength]="200"
                               required/>
                        <label>*{{'empresa'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-2 ">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text"
                               [maxLength]="100"
                               [(ngModel)]="model.vehicle"
                               required/>
                        <label>{{'veiculo'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-2 ">
                    <div class="input-material">
                        <input name="name" class="form-control text-uppercase" type="text"
                               [(ngModel)]="model.licensePlate"
                               [maxLength]="8"
                               required/>
                        <label>{{'placaveiculo'|translate}}</label>
                    </div>
                </div>
            </div>

        </div>


        <div class="card_ret row">
            <div class="col-12 col-md-3">
                    <span>
                        <strong>
                          {{'informacoes'|translate}}
                            <br></strong>
                        {{'ajudante'|translate}}
                     </span>
            </div>
            <div class="row col-12 col-md">
                <div class="col-12 col-md-5 mt-2">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text" [(ngModel)]="modelHelper.name"
                               required/>
                        <label>{{'nomeajudante'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-2 ">
                    <div class="input-material">
                        <input name="name" class="form-control" type="text" [(ngModel)]="modelHelper.doc"
                               required/>
                        <label>ID</label>
                    </div>
                </div>
                <button class="btn btn-warning  col-12 col-md-3 mt-2" (click)="actionHelperAdd()">
                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                    <span class="pl-2">{{'adicionar'|translate}}</span>
                </button>
                <div class="col-12 col-md-7">
                    <div class="row justify-content-between pt-5 pb-4">
                        <div class="col-6">
                            <span class="info-helper">
                        Nome
                            </span>
                        </div>
                        <div class="col-6">
                              <span class="info-helper-cpf">
                        ID
                            </span>
                        </div>
                    </div>
                    <ng-container *ngIf="isNullOrUndefined(model?.helper)">
                        <p>{{'nenhumajudantecadastro'|translate}}</p>
                    </ng-container>
                    <ng-container *ngFor="let x of model?.helper">
                        <div class="row justify-content-between border-bottom">
                            <div class="col-5">
                                <p>{{x.name}}</p>
                            </div>
                            <div class="col-5">
                                <p>{{x.doc}}</p>
                            </div>
                            <div class="col-2">
                                <a href="javascript:void('');" class="is-cursor" (click)="actionRemoveHelper(x);">
                                    <svg-icon src="assets/svg/close-red.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <div class="service-divider">&nbsp;</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <a class="btn btn-warning btn-lg btn-block flex-grow-1" (click)="actionSave()"
           style="max-width: 321px;margin: 0 auto">
            {{'salvar'|translate}}
        </a>
    </div>
</div>
