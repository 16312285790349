<div class="page-card secondary page-card-service is-cursor">
    <svg-icon routerLink="/services" src="assets/svg/arrow.svg"></svg-icon>
    <div class="pl-5 pt-4">
        <div class="page-header-custom secondary mb-4 pb-3">
            <p class="page-title">Data e Local</p>
            <h1 class="page-title secondary" style="text-align: left!important;">
                <span>{{'vistoriade'|translate}}</span>{{'ferramentas'|translate}}
            </h1>
            <button class="btn btn-outline-primary order-3 ml-auto" (click)="actionPdf()" *ngIf="actionUserProfile()">
                <svg-icon class="mr-3 pdf_svg svg-white" src="assets/svg/export.svg"></svg-icon>
                <span>Exportar</span>
            </button>
        </div>

        <div class="row">
            <div class="col-12 col-md">
                <p class="service-detail-lbl">{{'areaservico'|translate}}</p>
                <p>{{model?.area}}</p>
            </div>
            <div class="col-12 col-md">
                <p class="service-detail-lbl">{{'dataentrada'|translate}}</p>
                <p class="p-security">{{model?.entranceDate | date: 'dd/MM/yyyy'}}<br></p>
            </div>
            <div class="col-12 col-md">
                <p class="service-detail-lbl">{{'horaprevista'|translate}}</p>
                <p class="p-security">{{model?.entranceDate | date: 'HH:mm' : 'GMT'}}</p>
            </div>
            <div class="col-12 col-md">
                <p class="service-detail-lbl">{{'datasaida'|translate}}</p>
                <p class="p-security">{{model?.exitDate | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="col-12 col-md">
                <p class="service-detail-lbl">{{'horaprevista'|translate}}</p>
                <p class="p-security">{{model?.exitDate | date: 'HH:mm' : 'GMT'}}</p>
            </div>
        </div>
        <!--Responsável-->
        <div class="divider-detail">&nbsp;</div>
        <p class="page-title align-items-start pt-4">{{'responsible'|translate}}</p>
        <div class="row pt-4">
            <div class="col-12 col-md">
                <p class="service-detail-lbl">{{'name'|translate}}</p>
                <p>{{model?.createdBy?.name}}</p>
            </div>
            <div class="col-12 col-md">
                <p class="service-detail-lbl">E-mail</p>
                <p class="p-security">{{model?.createdBy?.email}}</p>
            </div>
            <div class="col-12 col-md-8">
                <p class="service-detail-lbl">Telefone</p>
                <p class="p-security">{{model?.createdBy?.phone}}</p>
            </div>
        </div>
        <div class="divider-detail">&nbsp;</div>
        <!--Informações do prestador-->
        <div class="row">
            <div class="col-8">
                <p class="page-title align-items-start pt-4">{{'informacoes'|translate}}<span> {{'doprestador'|translate}}</span></p>
                <div class="row pt-4">
                    <div class="col-12 col-md-4">
                        <p class="service-detail-lbl">{{'nomeprestador'|translate}}</p>
                        <p>{{model?.name}}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <p class="service-detail-lbl">ID</p>
                        <p class="p-security">{{model?.cpf}}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <p class="service-detail-lbl">CNPJ</p>
                        <p class="p-security">{{model?.cnpj}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <p class="service-detail-lbl">{{'empresa'|translate}}</p>
                        <p class="p-security">{{model?.company}}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <p class="service-detail-lbl">{{'veiculo'|translate}}</p>
                        <p class="p-security">{{model?.vehicle}}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <p class="service-detail-lbl">{{'placaveiculo'|translate}}</p>
                        <p class="p-security">{{model?.licensePlate}}</p>
                    </div>
                </div>
            </div>
            <!--Informações do ajudante-->
            <div class="col-12 col-md-4">
                <p class="page-title align-items-start pt-4">{{'informacoes'|translate}}<span> {{'dosajudantes'|translate}}</span></p>
                <div class="row justify-content-between pt-4">
                    <div class="col-6">
                        <p class="service-detail-lbl">{{'nomeajudante'|translate}}</p>
                    </div>
                    <div class="col-6">
                        <p class="service-detail-lbl">ID</p>
                    </div>
                </div>
                <ng-container *ngIf="isNullOrUndefined(model?.helper)">
                    <p>{{'nenhumajudantecadastro'|translate}}</p>
                </ng-container>
                <ng-container *ngFor="let x of model?.helper">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <p>{{x.name}} </p>
                        </div>
                        <div class="col-6">
                            <p>{{x.doc}}</p>
                        </div>
                    </div>
                    <div class="divider-detail">&nbsp;</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!--Log visita-->
<div class="container-services px-5">
    <p class="page-title align-items-star pt-4 mb-5">{{'logdevisita'|translate}}</p>

    <div class="table-responsive">
        <table class="table table_regular">
            <thead>
                <tr>
                    <th>{{'date'|translate}}</th>
                    <th>{{'tipo'|translate}}</th>
                    <th>Vigilante</th>
                    <th class="text-right w-5">Equipamentos</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let x of model.inspect">
                    <td>{{x.createdAt | date: 'dd/MM/yyyy - HH:mm' : 'GMT'}}</td>
                    <td>{{getTranslate(x.type)}}</td>
                    <td>{{x.createdBy?.name}}</td>
                    <td class="text-right">
                        <a [routerLink]="'/services/inspection/' + this.model.id + '/' + x.id" href="javascript:void('');">
                            <svg-icon  src="assets/svg/eye.svg" ></svg-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>


