import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User = new User();
    btnNew = false;
    pemission: Permissions = new Permissions();

    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                if (!this.isNullOrUndefined(data.id)) {
                    this.btnNew = data.profile === 'local' || data.profile === 'administrator';
                }
                this.actionUserProfile();
            }
        });
        // this.actionUserProfile();
        $('#sidebar').addClass('is-hide');
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                this.pemission = {
                    occurrence: true,
                    dashboard: true,
                    segurancaeletronica: true,
                    physicalSecurity: true,
                    gestaoR: true,
                    policiesProcedures: true,
                    truck: true,
                    services: true,
                    userM: true,
                    settings: true,
                };
                break;
            case 'local-manager':
                this.pemission = {
                    occurrence: true,
                    dashboard: true,
                    segurancaeletronica: true,
                    physicalSecurity: true,
                    gestaoR: true,
                    policiesProcedures: true,
                    truck: true,
                    services: true,
                    userM: true,
                    settings: true,
                };
                break;
            case 'user':
                this.pemission = {
                    occurrence: false,
                    dashboard: false,
                    segurancaeletronica: false,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: false,
                    services: true,
                    userM: false,
                    settings: false,
                };
                break;
            case 'vigilant-leader':
                this.pemission = {
                    occurrence: true,
                    dashboard: false,
                    segurancaeletronica: false,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: true,
                    services: true,
                    userM: false,
                    settings: false,
                };
                break;
            case 'vigilant-conductor':
                this.pemission = {
                    occurrence: true,
                    dashboard: false,
                    segurancaeletronica: false,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: true,
                    services: true,
                    userM: false,
                    settings: false,
                };
                break;
            case 'vigilant':
                this.pemission = {
                    occurrence: true,
                    dashboard: false,
                    segurancaeletronica: false,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: true,
                    services: true,
                    userM: false,
                    settings: false,
                };
                break;
            case 'lecturer':
                this.pemission = {
                    occurrence: false,
                    dashboard: false,
                    segurancaeletronica: false,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: true,
                    services: false,
                    userM: false,
                    settings: false,
                };
                break;
            case 'corporate-operator':
                this.pemission = {
                    occurrence: false,
                    dashboard: false,
                    segurancaeletronica: true,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: false,
                    services: false,
                    userM: false,
                    settings: false,
                };
                break;
            case 'local-operator':
                this.pemission = {
                    occurrence: false,
                    dashboard: false,
                    segurancaeletronica: true,
                    physicalSecurity: false,
                    gestaoR: false,
                    policiesProcedures: true,
                    truck: false,
                    services: false,
                    userM: false,
                    settings: false,
                };
                break;
        }
    }

    routerByprofile() {
        switch (this.user.profile) {
            case 'local-operator':
            case  'corporate-operator':
                return '/cftv';
            default:
                return '/access-control';
        }
    }
}

export class Permissions {
    occurrence: boolean;
    dashboard: boolean;
    segurancaeletronica: boolean;
    physicalSecurity: boolean;
    gestaoR: boolean;
    policiesProcedures: boolean;
    truck: boolean;
    services: boolean;
    userM: boolean;
    settings: boolean;

    constructor() {
        this.occurrence = false;
        this.dashboard = false;
        this.segurancaeletronica = false;
        this.physicalSecurity = false;
        this.gestaoR = false;
        this.policiesProcedures = false;
        this.truck = false;
        this.services = false;
        this.userM = false;
        this.settings = false;
    }
}

